
import Vue from "vue";
import { mapGetters } from "vuex";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("s-input", SmartInput);
Vue.component("s-button", SmartInput);
import Menu from "./components/Menu.vue";
import Navbar from "./components/Navbar.vue";
import SmartInput from "@/components/Apply/SmartInput.vue";
import SmartButton from "@/components/Apply/SmartButton.vue";
import LoaderDots from "@/components/LoaderDots.vue";
import DotLoader from "@/components/DotLoader.vue";
import CookieCivicConfig from "./common/cookie-civic.js";
import cookie from "js-cookie";
import { forceLogout, logoutAndClear, logoutWarning } from "./api/fetch-middleware";
import apiCall from "./api/fetch-middleware";
import api from "./api";
Vue.prototype.$cookie = cookie;
Vue.component("s-input", SmartInput);
Vue.component("s-button", SmartButton);
Vue.component("loader-dots", LoaderDots);
Vue.component("dot-loader-fullscreen", DotLoader);
export default Vue.extend({
  components: {
    "t-menu": Menu,
    "t-navbar": Navbar,
  },
  provide: {
    api() {
      return api;
    },
  },
  data: () => ({
    hifitheme: true,
    secondaryQuestions: [],
    onlyNavbar: false,
    timeLeft: 59, // seconds,
    extendedSession: false,
  }),
  created() {
    interface Window {
      dataLayer: any;
    }
    const currentWindow = window as any;
    if (currentWindow !== currentWindow.parent) {
      currentWindow.parent.location.href = currentWindow.location.href;
    }
  },
  watch: {
    hifitheme: {
      immediate: true,
      handler(v) {
        const classes = (this as any).$parent.$el.classList;
        classes[v ? "add" : "remove"].call(classes, "theme");
      },
    },
    displayLogoutWarning(newValue, oldValue) {
      if (newValue) {
        this.countDownTimer();
      } else {
        this.timeLeft = 59;
      }
    },
  },
  computed: {
    ...mapGetters(["loadingApplication", "brandingClassName"]),
    displayLogoutWarning: {
      get() {
        return this.$store.state.showLogoutWarning;
      },
      set(newValue) {
        return newValue;
      },
    },
    displayFPN: {
      get() {
        return this.$store.state.showFPNDialog;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    isExistScript(url) {
      var scripts = document.getElementsByTagName("script");
      for (var i = scripts.length; i--; ) {
        if (scripts[i].src == url) return true;
      }
      return false;
    },
    async handleOk() {
      const apiObj = await api;
      const apiUrl = apiObj.apiSpec.API;
      await apiCall.post(`${apiUrl}api/authorization/getAccessToken`);
      this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
      this.extendedSession = true;
    },
    async handleAcceptFPN() {
      this.$store.commit("SET_IS_FPN_CONFIRMED", true);
      this.$store.commit("SET_SHOW_FPN_DIALOG", false);
    },
    countDownTimer() {
      if (this.timeLeft > 0 && !this.extendedSession) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.timeLeft === 0 && !this.extendedSession) {
        // logout
        this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
        logoutAndClear();
      } else {
        // session extended set to default
        this.extendedSession = false;
      }
    }
  },
  mounted() {
    var shouldAdd = !this.isExistScript("https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js");
    if (shouldAdd) {
      var script = document.createElement("script");
      script.crossOrigin = "anonymous";
      script.src = "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js";
      document.body.appendChild(script);
      script.onload = function() {
        (window as any).CookieControl.load(CookieCivicConfig);
      };
    }
  },
});
